import React from "react"
import styles from "../components/header.module.css"
import { Link } from "gatsby"
import Images from "../images/image_vr.webp"
import { Helmet } from "react-helmet"

export default function Header() {
  return (
    <header>
      <Helmet>
        <html lang="ja" />
        <title>
          株式会社xCura (エクスキュラ)コーポレートサイト |
          株式会社xCuraは、テクノロジーによって『痛み』の軽減を行い、QOLの高い社会の実現を目指します。
          セラピアVRでは、VRディストラクションのコンテンツを作成し、VRゴーグルごと、病院・クリニックへ届け、新しい治療体験を提供致します。
        </title>
        <meta
          name="description"
          content="株式会社xCura(エクスキュラ)は、テクノロジーによって『痛み』の軽減を目指す企業です。XR Therapy事業では、VRディストラクションによって治療中の痛みや不安を軽減を目指し、負担の少ない医療体験を実現致します。"
        />
      </Helmet>
      <div className={styles.headerBox}>
        <h1>
          <Link to="/">xCura</Link>
        </h1>
        <img className={styles.image_vr} src={Images} alt="vr画像" />
      </div>
    </header>
  )
}
