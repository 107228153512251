import React from "react"
import styles from "../components/footer.module.css"

export default function Footer() {
  return (
    <footer>
      <div className={styles.footerBox}>
        <p>Copyright © xCura, Inc. All Rights Reserved.</p>
      </div>
    </footer>
  )
}
